import React from 'react';
import Header from '@components/Layout/Headers/EmployersHeader';
import Main from '@components/Layout/Main';
import EmployersFooter from '@components/Layout/Footer/EmployersFooter';
import ComingSoon from '@components/Layout/ComingSoon';
import LiveChatWidgetScript from '@components/LiveChatWidgetScript';
import '@scss/main.scss';

const EmployersLayout = ({
  children,
  className,
  isMobileComingSoon = true,
}) => {
  if (isMobileComingSoon) {
    return (
      <>
        <div className="desktop-only">
          <Header />
          <Main className={className}>{children}</Main>
          <EmployersFooter />
        </div>

        <div className="mobile-only">
          <ComingSoon />
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        <Header />
        <Main className={className}>{children}</Main>
        <EmployersFooter />
      </div>
      <LiveChatWidgetScript />
    </>
  );
};

export default EmployersLayout;
