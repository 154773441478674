import React from 'react';
import Layout from '@components/Layout/LayoutTemplates/EmployersLayout';
import SEO from '@components/SEO';
import EmployersFAQsResultsModule from '@modules/EmployersFAQs/Results';

const EmployersFAQsResultPage = () => {
  return (
    <Layout isReleased={true} isMobileComingSoon={false}>
      <SEO title="EmployersFAQs Results" description="EmployersFAQs Results" />
      <div className="faqs-results-section">
        <EmployersFAQsResultsModule />
      </div>
    </Layout>
  );
};

export default EmployersFAQsResultPage;
