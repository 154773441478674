import React, { useState } from 'react';
import classNames from 'classnames';

import MobileHamburgerMenu from '@components/HamburgerMenu';
import HeaderLogo from '../HeaderLogo';
import DesktopLeftLinks from '../DesktopLeftLinks';
import DesktopRightLinks from '../DesktopRightLinks';

const EmployerHeader = () => {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  const handleHamburgerMenuChange = (event) => {
    setIsHamburgerMenuOpen(event.target.checked);
  };

  return (
    <header className="header-section">
      <div
        className={classNames('header-container', {
          'header-fixed': isHamburgerMenuOpen,
        })}
      >
        <div className="header">
          <div className="left-menu">
            <HeaderLogo />
            <div className="large-menu-items">
              <DesktopLeftLinks headerType={'employers'} />
            </div>
          </div>

          <div className="right-menu">
            <div className="small-menu-items">
              <MobileHamburgerMenu
                headerType={'employers'}
                onChange={handleHamburgerMenuChange}
              />
            </div>
            <div className="large-menu-items">
              <DesktopRightLinks headerType={'employers'} />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default EmployerHeader;
