import React from 'react';
import FooterBanner from '@components/Layout/Footer/FooterBanner';
import { EmployerListsCollection } from '@components/Layout/Footer/FooterListsCollection';
import FooterSponsors from '@components/Layout/Footer/FooterSponsors';
import FooterCopyright from '@components/Layout/Footer/FooterCopyright';

const EmployersFooter = () => (
  <footer className="footer-container">
    <div className="footer">
      <div className="footer-upper-container">
        <FooterBanner />
        <EmployerListsCollection />
      </div>
      <div className="footer-lower-container">
        <FooterCopyright />
        <FooterSponsors />
      </div>
    </div>
  </footer>
);

export default EmployersFooter;
