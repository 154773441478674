import { useStaticQuery, graphql } from 'gatsby';
import useFlexSuggestionIndex from '@helpers/hooks/useFlexSuggestionIndex';

const Fetcher = ({ children }) => {
  const {
    allEmployerFaqs,
    flexSearchOptions,
    flexSuggestionOptions,
  } = useStaticQuery(
    graphql`
      {
        allEmployerFaqs: allStrapiFaqs(
          filter: { user_type: { eq: "Employers" } }
          sort: { fields: created_at, order: DESC }
        ) {
          nodes {
            answer
            id
            question
            user_type
          }
        }

        flexSearchOptions: localSearchFaqs {
          index
          store
        }

        flexSuggestionOptions: localSearchFaqsSuggestions {
          index
        }
      }
    `
  );

  let employerFaqs = allEmployerFaqs?.nodes || [];

  const suggestedWordsIndex = useFlexSuggestionIndex(
    flexSuggestionOptions?.index
  );

  const suggestedTitlesIndex = allEmployerFaqs?.nodes?.map(
    ({ question }) => question
  );

  const searchSuggestions = [...suggestedWordsIndex, ...suggestedTitlesIndex];

  return children({
    employerFaqs,
    searchSuggestions,
    flexSearchOptions,
  });
};

export default Fetcher;
